import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Angebote | Angebote bei RoadQuest Car Rentals
			</title>
			<meta name={"description"} content={"Verwirklichen Sie Ihre Träume mit unserer vielfältigen Flotte!"} />
			<meta property={"og:title"} content={"Angebote | Angebote bei RoadQuest Car Rentals"} />
			<meta property={"og:description"} content={"Verwirklichen Sie Ihre Träume mit unserer vielfältigen Flotte!"} />
			<meta property={"og:image"} content={"https://fluxarix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fluxarix.com/img/45367671.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fluxarix.com/img/45367671.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fluxarix.com/img/45367671.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fluxarix.com/img/45367671.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fluxarix.com/img/45367671.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fluxarix.com/img/45367671.png"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://fluxarix.com/img/4.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text as="h1" font="--headline1" margin="0 0 12px 0">
				Was RoadQuest bieten kann
				</Text>
				<Text font="--base">
				Entdecken Sie die breite Palette an Dienstleistungen von RoadQuest Car Rentals, die auf die Bedürfnisse jedes Reisenden zugeschnitten sind. Ob Sie ein Fahrzeug für geschäftliche, private oder besondere Anlässe benötigen, unsere umfangreiche Flotte und flexiblen Mietpläne sorgen dafür, dass Sie genau das finden, was Sie brauchen, wenn Sie es brauchen.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				margin="0px"
				font="--base"
				color="--dark"
				width="60%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				<Strong>
				Unsere Flotte
				</Strong>
				<br />
				Sparsame Autos: Perfekt für Stadtreisende, die Effizienz und Komfort suchen.
				<br />
				<br />
				Kraftstoffeffizienz: Ideal für lange Fahrten ohne häufige Tankstopps.
				<br />
				<br />
				Kompaktes Design: Navigieren Sie mühelos durch Verkehr und enge Parklücken.
				<br />
				<br />
				SUVs und Minivans: Ausgezeichnete Wahl für Familien und Gruppen, die mehr Platz und Leistung benötigen.
				<br />
				<br />
Geräumige Innenräume: Bequemer Platz für Gepäck, Campingausrüstung oder Sportgeräte.
				<br />
				<br />
Erweiterte Sicherheitsfunktionen: Ausgestattet mit der neuesten Sicherheitstechnologie zum Schutz aller Passagiere.
				<br />
				<br />
Luxusfahrzeuge: Für Gelegenheiten, bei denen Sie einen Hauch von Eleganz und überlegenen Komfort wünschen.
				<br />
				<br />
Premiumfunktionen: Genießen Sie Lederausstattung, Klimaanlage und fortschrittliche Multimediasysteme.
				<br />
				<br />
Hohe Leistung: Erleben Sie den Nervenkitzel des Fahrens mit leistungsstarken Motoren und sanftem Handling.
				<br />
				<br />
Spezialfahrzeuge: Einschließlich Cabrios und Geländewagen für Abenteuerlustige.
				<br />
				<br />
Cabriomodelle: Perfekt für malerische Fahrten entlang der Küste oder durch sonnige Landschaften.
				<br />
				<br />
Geländetauglichkeit: Bewältigen Sie jedes Gelände souverän mit unseren robusten 4x4-Optionen.

			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://fluxarix.com/img/5.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://fluxarix.com/img/6.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Entdecken Sie mehr mit RoadQuest
				<br />
				<br />
				Diese Übersicht kratzt nur an der Oberfläche dessen, was RoadQuest Car Rentals zu bieten hat. Um das gesamte Spektrum unserer Dienstleistungen zu erkunden und das perfekte Angebot für Ihre nächste Reise zu finden, kontaktieren Sie uns oder besuchen Sie unser Mietzentrum. Unser Team ist bereit, Ihnen bei der Zusammenstellung des idealen Mietpakets zu helfen.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});